<!--
 * @Author: your name
 * @Date: 2021-11-25 11:19:26
 * @LastEditTime: 2021-11-26 16:17:41
 * @LastEditors: Please set LastEditors
 * @Description: 解决方案
 * @FilePath: \new-website-1123\src\views\programme\index.vue
-->
<template>
  <div class="programme-page pt80">
    <transition
      name="page"
      enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="animate__animated animate__fadeOut"
    >
      <router-view></router-view>
    </transition>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style lang='scss' scoped>
.programme-page {
  background: #161616;
  min-height: calc(100vh - 402px);
}
</style>
